import { CacheProvider } from "@emotion/react";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { createContext, useCallback, useMemo, useState } from "react";
import createCache from "@emotion/cache";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { arSD } from "@mui/x-data-grid";
import { arSD as coreArSD } from "@mui/material/locale";
import IChildrenProps from "models/common-props/IChildrenProps";
import { AmeenColors, AmeenColorsDark } from "constants/ameenColors";
import i18n from "localization/i18n";

export type PaletteMode = "light" | "dark";

const isDarkPrefered =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const defaultMode: PaletteMode = isDarkPrefered ? "dark" : "light";

const ThemeContext = createContext({
  mode: defaultMode,
  setColorMode: (s: PaletteMode) => {},
});

export function ThemeContextProvider({ children }: IChildrenProps) {
  const localMode = localStorage.getItem("localMode") as PaletteMode;
  let currentMode = defaultMode;

  if (localMode) {
    currentMode = localMode;
  }
  const [mode, setMode] = useState<PaletteMode>(currentMode);

  const isRtl = i18n.language === "ar";
  const isAr = i18n.language === "ar";

  document.dir = isRtl ? "rtl" : document.dir;

  const cacheRtl = useMemo(
    () =>
      createCache({
        key: "muirtl",
        stylisPlugins: isRtl ? [prefixer, rtlPlugin] : [],
      }),
    [isRtl]
  );

  const localizationPlugins = useMemo(() => {
    let args: object[] = [];

    if (isAr) {
      args = [arSD, coreArSD];
    }
    return args;
  }, [isAr]);

  const theme = useMemo(
    () =>
      createTheme(
        {
          direction: isRtl ? "rtl" : undefined,
          palette: {
            mode: mode,

            primary: {
              main:
                mode === "dark" ? AmeenColorsDark.Primary : AmeenColors.Primary,
              contrastText: "#fff",
            },
            background: {
              default: mode === "dark" ? AmeenColorsDark.Background : "#F8F8F8",
            },
          },

          typography: {
            button: {
              textTransform: "none",
            },
            fontFamily: [
              "Alexandria",
              "Segoe UI",
              "Arial",
              "Roboto",
              "Helvetica Neue",
              "sans-serif",
              "Segoe UI",
              "Segoe UI Light",
            ].join(","),
            fontSize: 12,
            // fontWeightRegular: 500,
          },
          components: {
            MuiInput: {
              styleOverrides: {
                input: ({ theme, ownerState }) => ({
                  ":placeholder-shown": { color: "#95A9B8" },
                  color: "#25323E",
                  fontSize: "16px",
                  fontWeight: 300,
                  textAlign:
                    ownerState.type === "number" && !isRtl
                      ? "right"
                      : undefined,
                }),
              },
            },
            MuiIconButton: {
              styleOverrides: {
                root: {
                  "&:hover": {
                    backgroundColor: AmeenColors.Background,
                  },
                },
              },
            },
            MuiInputLabel: {
              styleOverrides: {
                root: {
                  color: mode === "dark" ? AmeenColors.OffWhite : "#25323E",
                  backgroundColor:
                    mode === "dark"
                      ? AmeenColorsDark.Gray
                      : AmeenColors.Background,
                },
              },
            },
            MuiFormLabel: {
              styleOverrides: {
                asterisk: {
                  color: "red",
                },
              },
            },
            MuiPaper: {
              styleOverrides: {
                root: {
                  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.08)",
                },
              },
            },
            MuiButton: {
              styleOverrides: {
                root: ({ ownerState }) => ({
                  ...(ownerState.variant === "outlined" &&
                    ownerState.color === "primary" && {
                      backgroundColor:
                        mode === "dark"
                          ? AmeenColorsDark.Background
                          : AmeenColors.Background,
                    }),
                  fontSize: "16px",
                  fontWeight: 500,
                  boxShadow: "none",
                }),
              },
            },
            MuiAppBar: {
              styleOverrides: {
                colorPrimary: {
                  backgroundColor:
                    mode === "dark"
                      ? AmeenColorsDark.Background
                      : AmeenColors.Background,
                  color:
                    mode === "dark"
                      ? AmeenColorsDark.Foreground
                      : AmeenColors.Foreground,
                },
              },
            },
            MuiFormControlLabel: {
              styleOverrides: {
                label: {
                  fontSize: "16px",
                  fontWeight: 300,
                },
              },
            },
            MuiOutlinedInput: {
              styleOverrides: {
                input: ({ theme, ownerState }) => ({
                  ":placeholder-shown": { color: "#95A9B8" },
                  color: "#25323E",
                  fontSize: "16px",
                  fontWeight: 300,
                  textAlign: ownerState.type === "number" ? "right" : undefined,
                }),
                root: {
                  "& .MuiOutlinedInput-notchedOutline": {
                    border:
                      "1px solid var(--components-input-outlined-enabledBorder, rgba(0, 0, 0, 0.23))",
                  },
                },
              },
            },
            MuiAutocomplete: {
              styleOverrides: {
                listbox: {
                  fontSize: 16,
                  fontWeight: 300,
                },
                popupIndicator: {
                  color: AmeenColors.CharcoalBlue,
                  "& svg": {
                    fontSize: "24px",
                  },
                },
              },
            },
            MuiRadio: {
              styleOverrides: {
                root: {
                  color: "#97C3F0",
                  "&.Mui-checked": {
                    color: "#1976D2",
                  },
                },
              },
            },
            MuiDrawer: {
              styleOverrides: {
                paper: {
                  backgroundColor:
                    mode === "dark"
                      ? AmeenColorsDark.Gray
                      : AmeenColors.Background, //"#fff",
                  color:
                    mode === "dark"
                      ? AmeenColorsDark.Foreground
                      : AmeenColors.Foreground,
                },
              },
            },
            MuiCardHeader: {
              styleOverrides: {
                title: {
                  fontSize: 18,
                  color: AmeenColors.GreenDark,
                },
              },
            },
            MuiTab: {
              styleOverrides: {
                root: ({ ownerState }) => ({
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  fontSize: "1rem",
                  padding: "1rem",
                  color:
                    mode === "dark"
                      ? AmeenColors.OffWhite
                      : AmeenColors.HardBlack,
                  "&.Mui-selected": {
                    backgroundColor:
                      mode === "dark" ? "#002D4C" : AmeenColors.BabyBlue,
                  },
                }),
              },
            },
            MuiGrid: {
              styleOverrides: {
                item: {
                  paddingBottom: "16px",
                },
              },
            },
            MuiCheckbox: {
              styleOverrides: {
                colorPrimary: {
                  background:
                    mode === "dark" ? AmeenColorsDark.OffWhite : "#E5E5E8",
                  padding: 0,
                  borderRadius: "5px",
                  color: mode === "dark" ? AmeenColorsDark.OffWhite : "#E5E5E8",
                },
              },
            },
            MuiDialog: {
              styleOverrides: {
                paper: {
                  overflow: "visible",
                  boxShadow: "none",
                  background:
                    mode === "dark" ? AmeenColorsDark.DialogBlack : "white",
                },
              },
            },
            MuiDialogTitle: {
              styleOverrides: {
                root: {
                  background:
                    mode === "dark" ? AmeenColorsDark.DialogBlack : "white",
                },
              },
            },
            MuiFormHelperText: {
              styleOverrides: {
                root: { maxWidth: "320px", fontSize: "12px" },
              },
            },
            MuiTypography: {
              styleOverrides: {
                root: {
                  color: mode === "dark" ? "" : AmeenColors.HardBlack,
                },
              },
            },
            MuiTextField: {
              defaultProps: {
                InputLabelProps: {
                  style: {
                    fontSize: "16px",
                  },
                },
              },
            },
          },
        },

        localizationPlugins
      ),
    [localizationPlugins, isRtl, mode]
  );

  const setColorMode = useCallback((s: "light" | "dark") => {
    localStorage.setItem("localMode", s);
    setMode(s);
    // window.location.reload();
  }, []);

  const context = useMemo(() => ({ mode, setColorMode }), [mode, setColorMode]);

  return (
    <ThemeContext.Provider value={context}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />

            {children}
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </ThemeContext.Provider>
  );
}

export default ThemeContext;

import { useTranslation } from "react-i18next";

export default function useTranslate(stringBase: string) {
  const { t } = useTranslation();

  function translate(key: string) {
    return t(`${stringBase}.${key}`);
  }

  function messageTrans(key: string) {
    if (key) return t(`core.message.${key}`);
    return "";
  }

  function buttonTrans(key: string) {
    if (key) return t(`core.button.${key}`);
    return "";
  }

  return { translate, messageTrans, buttonTrans };
}

import { ReactNode } from "react";
import { Grid, Stack } from "@mui/material";
import LanguageSelector from "components/general/LanguageSelector";
import { AmnCard } from "./AmnCard";

interface ICenteredCardProps {
  children: ReactNode;
  showLanguageSelector?: boolean;
  backgroundImage?: string;
  width?: any;
}

export default function CenteredCard({
  children,
  showLanguageSelector,
  backgroundImage,
  width,
}: ICenteredCardProps) {
  return (
    <Grid
      container
      spacing={0}
      alignItems={"center"}
      justifyContent={"center"}
      minHeight="100vh"
      sx={{ backgroundImage }}
    >
      <Grid
        item
        my={{ sm: "20px" }}
        width={
          width
            ? width
            : { xs: "100%", sm: "70%", md: "50%", lg: "35%", xl: "30%" }
        }
      >
        <AmnCard>
          {children}
          <Stack direction="row" justifyContent="space-between" mb={"14px"}>
            {showLanguageSelector && <LanguageSelector />}
          </Stack>
        </AmnCard>
      </Grid>
    </Grid>
  );
}

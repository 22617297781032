export enum TransactionTypes {
  sales,
  salesReturns,
  deliveryOrder,
  pickUpOrder,
}
export enum TicketStatus {
  new = -1,
  paid = 0,
  opened = 1,
  canceled = 2,
  refund = 3,
  deleted = 4,
}

import { useAppDispatch, useAppSelector } from "app/hooks";
import { updateTicket } from "amnPos/ticket/TicketSlice";
import {
  TicketStatus,
  TransactionTypes,
} from "models/common-props/TransactionTypes";
import {
  clientHandler,
  itemsHandler,
  statusHandler,
  typeHandler,
} from "amnPos/handlers/ticketHandlers/GeneralTicketChain";
import Ticket, { IClient } from "./Ticket";
import useExecuteHandler, {
  ICallbackTypes,
} from "amnPos/handlers/useExecuteHandler";
import { HandlerProps } from "amnPos/handlers/handler";

export interface ITicketPorps {
  id?: string;
}

export function useTicket(props: ITicketPorps) {
  const ticket = useAppSelector((x) => x.ticket);
  const dispatch = useAppDispatch();
  const { executeHandler, executeHandlerWithCallback, executeHandleAsync } =
    useExecuteHandler();

  const getTicketFromLocalStore = () => {
    return ticket;
  };

  const createNewTicket = (type: TransactionTypes) => {
    executeHandler(typeHandler, { item: ticket, value: type }).then(
      ({ item, status }) => {
        dispatch(updateTicket(item));
      }
    );
    return ticket;
  };

  const onTicketItemsUpdate = (props: HandlerProps<Ticket>) => {
    executeHandler(itemsHandler, props).then(({ item, status }) => {
      dispatch(updateTicket(item));
    });
  };

  const onTicketItemsUpdateAsync = async (props: HandlerProps<Ticket>) => {
    const result = await executeHandleAsync(itemsHandler, props);
    dispatch(updateTicket(result.item));
  };

  const changeStatus = (
    status: TicketStatus,
    callback: ICallbackTypes<Ticket>,
    t?: Ticket
  ) => {
    executeHandlerWithCallback(
      statusHandler,
      { item: t ? t : ticket, value: status },
      callback
    );
  };

  const onTicketAddClient = (
    client: IClient,
    callback: (result: Ticket) => void
  ) => {
    executeHandler(clientHandler, {
      item: ticket,
      value: { id: client.id, name: client.name },
    }).then((resp) => {
      dispatch(updateTicket(resp.item));
      callback(resp.item);
    });
  };

  return {
    getTicketFromLocalStore,
    createNewTicket,
    onTicketItemsUpdate,
    onTicketItemsUpdateAsync,
    changeStatus,
    onTicketAddClient,
  };
}

import {
  ValidateResault,
  IValidator,
  ValidateStatus,
  NotValidResult,
} from "amnPos/validators/validator";

export enum HandlerStatus {
  Error,
  Success,
}

export type SuccessResult<T> = {
  status: HandlerStatus.Success;
  item: T;
};

export type ErrorResult<T> = {
  status: HandlerStatus.Error;
  item: T;
  validaionResult: NotValidResult;
};

export type IHandlerResult<T> = SuccessResult<T> | ErrorResult<T>;

export type HandlerProps<T> = {
  value?: any;
  item: T;
};

export interface IHandler<T> {
  setNext(handler: IHandler<T>): IHandler<T>;
  handle(props: HandlerProps<T>): Promise<IHandlerResult<T>>;
}

export abstract class PosHandler<T> implements IHandler<T> {
  private nextHandler: IHandler<T> | undefined;

  protected validator: IValidator<T> | undefined;

  public setNext(handler: IHandler<T>): IHandler<T> {
    this.nextHandler = handler;
    return handler;
  }

  private async validate(props: HandlerProps<T>): Promise<ValidateResault> {
    return Promise.resolve(
      this.validator?.validate({ ...props }) || {
        status: ValidateStatus.Success,
      }
    );
  }

  protected abstract processHandle(
    props: HandlerProps<T>
  ): Promise<IHandlerResult<T>>;

  public async handle(props: HandlerProps<T>): Promise<IHandlerResult<T>> {
    try {
      const validationResult = await this.validate(props);

      if (validationResult.status === ValidateStatus.Error) {
        return {
          item: props.item,
          status: HandlerStatus.Error,
          validaionResult: validationResult,
        };
      }

      const handlerResult = await this.processHandle(props);

      switch (handlerResult.status) {
        case HandlerStatus.Success: {
          if (this.nextHandler) {
            const nextHandlerResult = await this.nextHandler.handle({
              item: handlerResult.item,
            });
            return nextHandlerResult;
          }

          return {
            item: handlerResult.item,
            status: handlerResult.status,
          };
        }
        case HandlerStatus.Error: {
          return {
            item: props.item,
            status: handlerResult.status,
            validaionResult: {
              status: ValidateStatus.Error,
              errorMsg: "error",
            },
          };
        }
      }
    } catch (error) {
      return {
        item: props.item,
        status: HandlerStatus.Error,
        validaionResult: {
          status: ValidateStatus.Error,
          errorMsg: String(error),
        },
      };
    }
  }
}

import { AxiosResponse } from "axios";
import spreadErrors from "helpers/spreadErrors";
import { ISnackbar } from "hooks/useSnackbar";

type ConfiguratorType<T> = (
  data: T
) => [string | undefined, (() => void) | undefined];

export default function reportHttpErrors<T>(
  response: AxiosResponse,
  snackContext: ISnackbar,
  actionConfigurator?: ConfiguratorType<T>
) {
  if (response?.status === 400) {
    let actionName: string | undefined;
    let action: (() => void) | undefined;

    if (actionConfigurator) {
      [actionName, action] = actionConfigurator(response.data);
    }

    spreadErrors(response.data.errors).forEach((item) =>
      snackContext.setSnack(item, "error", undefined, actionName, action)
    );

    if (response.data.value)
      snackContext.setSnack(
        response.data.value,
        "error",
        undefined,
        actionName,
        action
      );
  } else if (response.status === 503) {
    snackContext.setSnack("Service Connection Problem.", "error");
  }
}

import IChildrenProps from "models/common-props/IChildrenProps";
import { createContext, useCallback, useMemo, useState } from "react";

interface INotification {
  id: string;
  description: string;
  link: string;
}

const NotificationsContext = createContext({
  getAllNotifications: () => [] as INotification[],
  addNotification: (id: string, description: string, link: string) => {},
});

export function NotificationsContextProvider({ children }: IChildrenProps) {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const getAllNotifications = useCallback(() => notifications, [notifications]);

  const addNotification = useCallback(
    (id: string, description: string, link: string) => {
      setNotifications((notifications) => {
        if (notifications.some((x) => x.id === id)) return notifications;
        else return [...notifications, { id, description, link }];
      });
    },
    []
  );

  const context = useMemo(
    () => ({ getAllNotifications, addNotification }),
    [getAllNotifications, addNotification]
  );

  return (
    <NotificationsContext.Provider value={context}>
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsContext;

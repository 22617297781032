export enum ValidateStatus {
  Success,
  Error,
}

export type ValidResult = {
  status: ValidateStatus.Success;
};

export type NotValidResult = {
  status: ValidateStatus.Error;
  errorMsgTitle?: string;
  errorMsg: string;
};

export type ValidateResault = ValidResult | NotValidResult;

export type IValidatorProps<T> = {
  value?: any;
  item: T;
};

export interface IValidator<T> {
  validate(props: IValidatorProps<T>): ValidateResault;
}
export abstract class PosValidator<T> implements IValidator<T> {
  public validate(props: IValidatorProps<T>): ValidateResault {
    return { status: ValidateStatus.Success };
  }
}

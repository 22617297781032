import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import IAsyncModel from "models/IAsyncModel";
import amnPosApi from "api/amnPosApi";
import IPosProperties from "adminPanel/pos/models/IPosProperties";
import { EmptyPosProperties } from "adminPanel/pos/models/IPosProperties";

export interface PosSettingSlice extends IAsyncModel, IPosProperties {}

const initialState: PosSettingSlice = EmptyPosProperties;
export const getPosSettingAsync = createAsyncThunk(
  "pos/PosSetting",
  async (posId: string) => {
    const resp = await amnPosApi.GetPosSetting(posId);
    
    return {
      id: resp.data.id,
      name: resp.data.name,
      updatedOn: resp.data.updatedOn,
      store: resp.data.store,
      users: resp.data.users,
      currencies: resp.data.currencies,
      returnOptions: resp.data.returnOptions,
      ordersOptions: resp.data.ordersOptions,
      roundOptions: resp.data.roundOptions,
      settleOptions: resp.data.settleOptions,
      posPrintDesigns: resp.data.posPrintDesigns,
      printingServiceUrl: resp.data.printingServiceUrl,
    } as IPosProperties;
  }
);

export const posSettingSlice = createSlice({
  name: "posSetting",
  initialState,
  reducers: {
    initializePosSeetings: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPosSettingAsync.fulfilled, (_, action) => {
        return action.payload;
      })
      .addCase(getPosSettingAsync.rejected, () => {
        return initialState;
      });
  },
});

export const { initializePosSeetings } = posSettingSlice.actions;

export const posSetting = (state: RootState) => state.posSetting;

export default posSettingSlice.reducer;

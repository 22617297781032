import { TicketItem } from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../handler";
import { QtyValidator } from "amnPos/validators/ticketItemValidators/QtyValidator";

export class QtyHandler extends PosHandler<TicketItem> {
  constructor() {
    super();
    this.validator = new QtyValidator();
  }
  protected processHandle(
    props: HandlerProps<TicketItem>
  ): Promise<IHandlerResult<TicketItem>> {
    const { item, value } = props;

    if (value) {
      item.qty = Number(value);

      if (item.qty.isLessThan(item.giftQty)) item.giftQty = 0;
    }

    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}

import Item from "amnPos/item/Item";
import ItemApi from "amnPos/item/itemApi";
import { useAppSelector } from "app/hooks";
import {
  discountHandler,
  initHandler,
  priceHandler,
  qtyHandler,
  totalHandler,
  giftHandler,
} from "amnPos/handlers/ticketItemHandlers/GeneralTicketItemChain";
import { v4 as uuid4 } from "uuid";
import { HandlerProps } from "amnPos/handlers/handler";
import Ticket, { ItemDiscount, TicketItem } from "amnPos/ticket/Ticket";
import { useTicket } from "amnPos/ticket/useTicket";
import { useState } from "react";
import useAmnNavigate from "hooks/useAmnNavigate";
import useExecuteHandler, {
  ICallbackTypes,
  ICallbackTypesAsync,
} from "amnPos/handlers/useExecuteHandler";
import { TicketStatus } from "models/common-props/TransactionTypes";
import { store } from "app/store";

export interface ITicketItemPorps {
  beforeAddItem?: (item: any) => boolean;
  afterAddItem?: (item: any) => boolean;
  id?: string;
}

export function useTicketItem(props: ITicketItemPorps) {
  const ticketItems = useAppSelector((x: any) => x.ticket.ticketItems);
  const [isLoading, setIsLoading] = useState(false);
  const {
    getTicketFromLocalStore,
    onTicketItemsUpdate,
    onTicketItemsUpdateAsync,
    changeStatus,
  } = useTicket({});
  const [ticketItem, setTicketItem] = useState<TicketItem>(
    ticketItems.find((i: TicketItem) => i.id === props.id)
  );

  const { amnNavigate } = useAmnNavigate();
  const getTicketItemFromApi = (id: string) => {
    return ItemApi.CreateTicketItem(id);
  };
  const { executeHandlerWithCallback, executeHandlerWithCallbackAsync } =
    useExecuteHandler();

  const getTicketItemFromLocalStore = (id: string) => {
    return ticketItems.find((i: TicketItem) => i.id === id);
  };

  const getTicketItemFromLocalStorebyItemId = (itemId: string) => {
    return ticketItems.find((i: TicketItem) => i.itemId === itemId);
  };

  const afterHandlerExecute = (callback: ICallbackTypes<TicketItem>) => {
    return {
      onSuccess: (result: TicketItem) => {
        setTicketItem(result);
        callback.onSuccess(result);
      },
      onFailure: () => {
        if (callback.onFailure) callback.onFailure(ticketItem);
      },
    };
  };

  const afterHandlerExecuteAsync = async (
    callback: ICallbackTypesAsync<TicketItem>
  ) => {
    return {
      onSuccess: async (result: TicketItem) => {
        setTicketItem(result);
        await callback.onSuccess(result);
      },
      onFailure: async () => {
        if (callback.onFailure) await callback.onFailure(ticketItem);
      },
    };
  };

  const onEditItemPriceHandler = (
    props: HandlerProps<TicketItem>,
    callback: ICallbackTypes<TicketItem>
  ) => {
    executeHandlerWithCallback<TicketItem>(
      priceHandler,
      props,
      afterHandlerExecute(callback)
    );
  };

  const onEditItemValueHandler = (
    props: HandlerProps<TicketItem>,
    callback: ICallbackTypes<TicketItem>
  ) => {
    executeHandlerWithCallback<TicketItem>(
      totalHandler,
      props,
      afterHandlerExecute(callback)
    );
  };

  const onEditItemQtyHandler = (
    props: HandlerProps<TicketItem>,
    callback: ICallbackTypes<TicketItem>
  ) => {
    executeHandlerWithCallback(
      qtyHandler,
      props,
      afterHandlerExecute(callback)
    );
  };

  const onEditItemQtyHandlerAsync = async (
    props: HandlerProps<TicketItem>,
    callback: ICallbackTypesAsync<TicketItem>
  ) => {
    await executeHandlerWithCallbackAsync(
      qtyHandler,
      props,
      await afterHandlerExecuteAsync(callback)
    );
  };

  const onEditItemDiscountHandler = (
    props: HandlerProps<TicketItem>,
    callback: ICallbackTypes<TicketItem>
  ) => {
    executeHandlerWithCallback(
      discountHandler,
      props,
      afterHandlerExecute(callback)
    );
  };

  const onEditItemGiftHandler = (
    props: HandlerProps<TicketItem>,
    callback: ICallbackTypes<TicketItem>
  ) => {
    executeHandlerWithCallback(
      giftHandler,
      props,
      afterHandlerExecute(callback)
    );
  };

  const onInitTicketItemsHandler = (
    props: HandlerProps<TicketItem>,
    callback: ICallbackTypes<TicketItem>
  ) => {
    executeHandlerWithCallback(
      initHandler,
      props,
      afterHandlerExecute(callback)
    );
  };

  const beforeAddItem = (item: Item) => {
    if (!props.beforeAddItem) return true;
    return props.beforeAddItem(item);
  };

  const afterAddItem = (item: Item) => {
    if (!props.afterAddItem) return true;
    return props.afterAddItem(item);
  };

  const handelAddItem = async (item: Item) => {
    if (!item.qty || item.qty === 0) return;
    if (!beforeAddItem(item)) return;

    const localItem: TicketItem = store
      .getState()
      .ticket.ticketItems.find((i) => i.itemId === item.id) as TicketItem;

    if (localItem) {
      await onEditItemQtyHandlerAsync(
        {
          item: localItem,
          value: (localItem.qty as number).plus(item.qty),
        },
        {
          async onSuccess(result: TicketItem) {
            await updateItem(result);
          },
        }
      );
      return;
    }
    const resp = await getTicketItemFromApi(item.id);
    await onEditItemQtyHandlerAsync(
      { item: { ...resp.data, id: uuid4() }, value: item.qty },
      {
        async onSuccess(result: TicketItem) {
          await onTicketItemsUpdateAsync({
            item: store.getState().ticket,
            value: [...store.getState().ticket.ticketItems, result],
          });
        },
      }
    );
    afterAddItem(item);
  };

  const addItem = async (item: Item) => {
    setIsLoading(true);
    if (!isLoading) {
      handelAddItem(item).finally(() => {
        setIsLoading(false);
      });
    }
  };

  const updateItem = async (item: TicketItem) => {
    await onTicketItemsUpdateAsync({
      item: store.getState().ticket,
      value: store
        .getState()
        .ticket.ticketItems.map((i: TicketItem) =>
          i.id === item.id ? item : i
        ),
    });
  };

  const deleteItem = (itemId: string) => {
    onTicketItemsUpdate({
      item: getTicketFromLocalStore(),
      value: ticketItems.filter((item: TicketItem) => item.id !== itemId),
    });
    amnNavigate("/pos");
  };

  const onEditAllItemsDiscount = (value: ItemDiscount) => {
    onEditTicketItems(
      onEditItemDiscountHandler,
      {
        item: getTicketFromLocalStore(),
        value,
      },
      (res: Ticket) => {
        onTicketItemsUpdate({ item: res, value: res.ticketItems });
      }
    );
  };

  const onInsertTicket = (ticket: Ticket, callback?: any) => {
    changeStatus(
      TicketStatus.opened,
      {
        onSuccess: (resul: Ticket) => {
          onEditTicketItems(
            onInitTicketItemsHandler,
            { item: resul },
            (res: Ticket) => {
              onTicketItemsUpdate({ item: res, value: res.ticketItems });
              callback?.onSuccess(res, store.getState().ticket);
            }
          );
        },
      },
      ticket
    );
  };

  const onEditTicketItems = async (
    handler: any,
    props: HandlerProps<Ticket>,
    callback?: (ticket: Ticket) => void
  ) => {
    await Promise.all(
      props.item.ticketItems.map(
        (ticketItem: TicketItem): Promise<TicketItem> =>
          new Promise((resolve: any, reject: any) => {
            handler(
              { item: ticketItem, value: props.value },
              afterHandlerExecute({
                onSuccess: (result: TicketItem) => {
                  resolve(result);
                },
                onFailure: () => {
                  reject();
                },
              })
            );
          })
      )
    )
      .then((res: TicketItem[]) => {
        if (callback) callback({ ...props.item, ticketItems: res });
      })
      .catch(() => {});
  };

  const isAnyItemHasDiscount = () =>
    ticketItems.some((item: TicketItem) => item.discountValue > 0);

  return {
    getTicketItemFromLocalStore,
    getTicketItemFromLocalStorebyItemId,
    onEditAllItemsDiscount,
    onInsertTicket,
    updateItem,
    addItem,
    deleteItem,
    onEditItemPriceHandler,
    onEditItemQtyHandler,
    onEditItemDiscountHandler,
    onEditItemGiftHandler,
    onEditItemValueHandler,
    isAnyItemHasDiscount,
  };
}

import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import IChildrenProps from "models/common-props/IChildrenProps";

//this is used to make useBlocker work until it is added back into react-router-dom
export default function BrowserRouter({ children }: IChildrenProps) {
  return (
    <HistoryRouter history={createBrowserHistory({ window }) as any}>
      {children}
    </HistoryRouter>
  );
}

import { useAppSelector } from "app/hooks";
import { AppType } from "constants/AppType";
import getAdminsPath from "helpers/getAdminsPath";
import { useNavigate } from "react-router-dom";

const useAmnNavigate = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);

  const getOrganizationPath = (path: string) => {
    const organization =
      user.organizationId !== "" ? user.organizationId : ":organizationId";
    return `/${organization}${path}`;
  };

  const getNavigationPath = (
    pathname: string,
    appType: AppType = AppType.Organizations
  ) => {
    switch (appType) {
      case AppType.Admins:
        return getAdminsPath(pathname);
      case AppType.Organizations:
        return getOrganizationPath(pathname);
    }
  };

  const amnNavigate = (
    pathname: any,
    appType = AppType.Organizations,
    navigateOptions?: any
  ) => {
    const modifiedPathname = getNavigationPath(pathname, appType);

    navigate(modifiedPathname, navigateOptions);
  };

  return { amnNavigate, getNavigationPath };
};

export default useAmnNavigate;

export const playBeep = () => {
  const audioContext = new (window.AudioContext || window.AudioContext)();

  const oscillator = audioContext.createOscillator();
  oscillator.type = "sine"; // avilable types: { 'square', 'sawtooth', or 'triangle' }
  oscillator.frequency.setValueAtTime(440, audioContext.currentTime);

  const gainNode = audioContext.createGain();
  gainNode.gain.setValueAtTime(1, audioContext.currentTime);

  oscillator.connect(gainNode);
  gainNode.connect(audioContext.destination);

  oscillator.start();

  oscillator.stop(audioContext.currentTime + 0.5);
};

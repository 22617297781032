import { SnackbarContextProvider } from "context/SnackbarContext";
import { ThemeContextProvider } from "context/ThemeContext";
import { ConfirmationDialogContextProvider } from "context/ConfirmationDialogContext";
import { NotificationsContextProvider } from "context/NotificationsContext";
import IChildrenProps from "models/common-props/IChildrenProps";
import { MessageDialogContextProvider } from "./MessageDialogContext";

export default function AppContextProvider({ children }: IChildrenProps) {
  return (
    <ThemeContextProvider>
      <NotificationsContextProvider>
        <SnackbarContextProvider>
          <ConfirmationDialogContextProvider>
            <MessageDialogContextProvider>
              {children}
            </MessageDialogContextProvider>
          </ConfirmationDialogContextProvider>
        </SnackbarContextProvider>
      </NotificationsContextProvider>
    </ThemeContextProvider>
  );
}

import { ItemDiscount, TicketItem } from "amnPos/ticket/Ticket";
import {
  PosValidator,
  ValidateResault,
  IValidatorProps,
  ValidateStatus,
} from "../validator";
import { store } from "app/store";

export class DiscountValidator extends PosValidator<TicketItem> {
  public validate(props: IValidatorProps<TicketItem>): ValidateResault {
    const { item, value } = props;
    const user = store.getState().user;

    const discValue =
      Number((value as ItemDiscount)?.discValue) || Number(item.discountValue);
    const discPercent =
      (value as ItemDiscount)?.discPercent || item.discountPercent;

    if (discValue) {
      if (discValue.isLessThan(0))
        return {
          status: ValidateStatus.Error,
          errorMsgTitle: "discountNotAllowed",
          errorMsg: "valueIsLessThanZero",
        };

      if (discValue.isGreaterThan(item.total))
        return {
          status: ValidateStatus.Error,
          errorMsgTitle: "discountNotAllowed",
          errorMsg: "discountIsGreaterThanItemValue",
        };

      const percentage = discValue.dividedBy(item.total).multipliedBy(100);
      if (
        percentage.isGreaterThan(user.itemDisCountPercentage) &&
        user.makeDiscountOnItem
      ) {
        return {
          status: ValidateStatus.Error,
          errorMsgTitle: "discountNotAllowed",
          errorMsg: "discountPercentageGreaterThanAllowed",
        };
      }
    }

    if (discPercent) {
      if (discPercent.isLessThan(0))
        return {
          status: ValidateStatus.Error,
          errorMsgTitle: "discountNotAllowed",
          errorMsg: "valueIsLessThanZero",
        };

      if (discPercent.isGreaterThan(100))
        return {
          status: ValidateStatus.Error,
          errorMsgTitle: "discountNotAllowed",
          errorMsg: "discountPercentageIsGreaterThanOneHundred",
        };

      if (
        discPercent.isGreaterThan(user.itemDisCountPercentage) &&
        user.makeDiscountOnItem
      ) {
        return {
          status: ValidateStatus.Error,
          errorMsgTitle: "discountNotAllowed",
          errorMsg: "discountPercentageGreaterThanAllowed",
        };
      }

      if (item.total.isLessThanOrEqualTo(0))
        return {
          status: ValidateStatus.Error,
          errorMsgTitle: "discountNotAllowed",
          errorMsg: "discountIsGreaterThanItemValue",
        };
    }

    return { status: ValidateStatus.Success };
  }
}

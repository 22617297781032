import axios from "axios";
import getApiBaseUrl from "helpers/getApiBaseUrl";
import Item from "./Item";

const baseUrl = getApiBaseUrl();

function GetItemsList(selectedCategoryID: string, criteria: any) {
  return axios.post(
    baseUrl + `posItem/get-all-material-items/${selectedCategoryID}`,
    criteria
  );
}

function GetItem(id: string) {
  return axios.get(baseUrl + `posItem/get-item/${id}`);
}

function CreateTicketItem(id: string) {
  return axios.get(baseUrl + `posItem/create-ticket-item/${id}`);
}

function GetItemsByBarcode(barcode: string) {
  return axios.get<Item[]>(baseUrl + `posItem/get-item-by-barcode/${barcode}`);
}

const ItemApi = { GetItemsList, GetItem, CreateTicketItem, GetItemsByBarcode };

export default ItemApi;

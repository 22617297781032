import { useLocation } from "react-router-dom";

interface IErrorPageProps {
  error?: string;
}

export default function ErrorPage({ error }: IErrorPageProps) {
  const location = useLocation();
  let err;
  if (location.state.error) {
    err = location.state.error;
  } else if (error) {
    err = error;
  }

  if (err) {
    return (
      <div style={{ color: "red" }}>
        <h1>An Error With the following properties happened:</h1>
        <p>{err}</p>
      </div>
    );
  }

  return <h1 style={{ color: "red" }}>Error Page</h1>;
}

import { CardMedia, Icon } from "@mui/material";

interface IFlagIconProps {
  code: string;
  [s: string]: any;
  ml?: string;
}

export default function FlagIcon({ code, ml, ...rest }: IFlagIconProps) {
  return (
    <Icon
      {...rest}
      style={{
        width: rest.width ? rest.width : 20,
        height: rest.height ? rest.height : 15,
        marginLeft: ml ? ml : 0,
      }}
    >
      <CardMedia
        component="img"
        image={`/icons/flags/${code?.toUpperCase()}.svg`}
        sx={{
          width: rest.width ? rest.width : "20px",
          height: rest.height ? rest.height : "15px",
        }}
        alt={code}
      />
    </Icon>
  );
}

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import MainButton from "amnPos/generalComponent/MainButton";
import { AmeenColors } from "constants/ameenColors";
import { MessageDialogType } from "constants/messageDialogType";
import useTranslate from "hooks/useTranslate";
import IChildrenProps from "models/common-props/IChildrenProps";
import React, { useCallback, useMemo } from "react";
import { Suspense, createContext, useState } from "react";

const MessageDialogIcon = React.lazy(
  () => import("components/icons/MessageDialogIcon")
);

const MessageDialogContext = createContext({
  setMessageInfo: (
    messageHeader: string,
    messageDescription: string,
    messageType: MessageDialogType,
    bottonText?: string
  ) => {},

  setErrorMessageInfo: (
    messageHeader: string,
    messageDescription: string,
    bottonText?: string,
    afterCloseAction?: any
  ) => {},
});

export function MessageDialogContextProvider({ children }: IChildrenProps) {
  const { buttonTrans } = useTranslate("");
  const [open, setOpen] = useState(false);
  const [messageHeader, setMessageHeader] = useState("");
  const [messageDescription, setMessageDescription] = useState("");
  const [bottonText, setBottonText] = useState(buttonTrans("ok"));
  const [type, setType] = useState<MessageDialogType>(MessageDialogType.Error);
  const [afterClose, setAfterClose] = useState<Function | null>(null);

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  function handleClose(event: any, reason: any) {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    if (afterClose) afterClose();
  }

  const setMessageInfo = useCallback(
    (
      messageHeader: string,
      messageDescription: string,
      messageType: MessageDialogType,
      bottonText?: string
    ) => {
      setOpen(true);
      setMessageHeader(messageHeader);
      setMessageDescription(messageDescription);
      setType(messageType);
      bottonText && setBottonText(buttonTrans(bottonText));
    },
    [buttonTrans]
  );

  const setErrorMessageInfo = useCallback(
    (
      messageHeader: string,
      messageDescription: string,
      bottonText?: string,
      afterCloseAction?: Function | null
    ) => {
      setOpen(true);
      setMessageHeader(messageHeader);
      setMessageDescription(messageDescription);
      setType(MessageDialogType.Error);
      bottonText && setBottonText(buttonTrans(bottonText));
      if (afterCloseAction) setAfterClose(() => afterCloseAction);
    },
    [buttonTrans]
  );

  const context = useMemo(
    () => ({ setMessageInfo, setErrorMessageInfo }),
    [setMessageInfo, setErrorMessageInfo]
  );

  return (
    <MessageDialogContext.Provider value={context}>
      {children}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <Stack alignItems="center" position="relative">
          <DialogTitle
            id="message-dialog-title"
            style={{
              borderRadius: "50%",
              boxShadow: isDark
                ? "0px 8px 13px rgba(0, 0, 0, 0.20)"
                : "0px 4px 4px rgba(0, 0, 0, 0.07)",
              position: "absolute",
              top: "-50px",
              padding: "10px",
            }}
          >
            <Suspense fallback={<div>Loading ...</div>}>
              <MessageDialogIcon iconType={type} isDark={isDark} />
            </Suspense>
          </DialogTitle>
        </Stack>

        <Stack alignItems={"center"}>
          <DialogContent
            sx={{
              mt: "60px",
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                marginBottom: "3px",
              }}
            >
              {messageHeader}
            </Typography>

            {messageDescription && (
              <DialogContentText
                id="message-dialog-description"
                paddingTop="5px"
                sx={{
                  fontSize: "18px",
                  fontWeight: "400",
                  textAlign: "center",
                  color: isDark ? "#A5A5A5" : "#667987",
                }}
              >
                {messageDescription}
              </DialogContentText>
            )}
          </DialogContent>

          <DialogActions sx={{ marginBottom: "24px", paddingTop: "12px" }}>
            <MainButton
              backgroundColor={AmeenColors.Primary}
              fontColor={AmeenColors.OffWhite}
              onClick={handleClose}
              children={bottonText}
              width="100px"
            />
          </DialogActions>
        </Stack>
      </Dialog>
    </MessageDialogContext.Provider>
  );
}
export default MessageDialogContext;

import axios from "axios";
import getApiBaseUrl from "helpers/getApiBaseUrl";
import {
  CloseShiftParameters,
  OpenShiftParameters,
  OpeningShiftCashDto,
  ShiftInfo,
  OpenShiftResult,
  UnCloseShiftParameters,
  ShiftCurrenciesExchangeRateItem,
} from "./ShiftDataModel";

const baseUrl = getApiBaseUrl() + "Shift/";
function GetOpeningShiftCash(posId: string) {
  return axios.get<OpeningShiftCashDto[]>(
    baseUrl + `get-opening-shift-cash/${posId}`
  );
}

function GetShiftCurrenciesExchangeRate(shiftId: string) {
  return axios.get<ShiftCurrenciesExchangeRateItem[]>(
    baseUrl + `get-shift-cash-currencies-exchangerate/${shiftId}`
  );
}

function GetShiftInfo(posId: string) {
  return axios.get<ShiftInfo>(baseUrl + `get-shift-info/${posId}`);
}
function ValidateShift(posId: string) {
  return axios.get<OpenShiftResult>(baseUrl + `validate-shift/${posId}`);
}
function OpenShift(parameters: OpenShiftParameters) {
  return axios.post<OpenShiftResult>(baseUrl + "open-shift", {
    parameters: parameters,
  });
}
function CloseShift(parameters: CloseShiftParameters) {
  return axios.post<boolean>(baseUrl + "close-shift", {
    parameters: parameters,
  });
}
function UnCloseShift(parameters: UnCloseShiftParameters) {
  return axios.post<boolean>(baseUrl + "unclose-shift", {
    parameters: parameters,
  });
}
const shiftAPI = {
  GetOpeningShiftCash,
  GetShiftCurrenciesExchangeRate,
  GetShiftInfo,
  OpenShift,
  CloseShift,
  UnCloseShift,
  ValidateShift,
};

export default shiftAPI;

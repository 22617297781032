import { ICurrency } from "adminPanel/pos/models/IPosProperties";
import { NIL as uuidNil } from "uuid";

export enum ShiftState {
  Open,
  Closed,
}

export enum OpenShiftStatus {
  NoShift = 0,
  ShiftWithSameUserSameDevice = 1,
  ShiftWithSameUserAnotherDevice = 2,
  ShiftWithAnotherUserSameDevice = 3,
  Success = 4,
  Error = 5,
  UserIsNotWorking = 6,
  SaleBillTypeNotMatched = 7,
  ErrorInOpenShiftDate = 8,
}

type ShiftCashDto = {
  currency: ICurrency;
};

export type OpeningShiftCashDto = ShiftCashDto & {
  floatCashAmount: number;
  floatCashCurVal: number;
};

export type ShiftCurrenciesExchangeRateItem = ShiftCashDto & {
  exchangeAverage: number;
  calculatedAmount: number;
};

export type ShiftCashCurrencyDto = {
  currencyId: string;
  countedAmount: number;
  deliveredToTheCentralBox: number;
  receiptNo: string;
  floatAmount: number;
  floatCashCurVal: number;
};

export type OpenShiftParameters = {
  employeeId: string;
  posId: string;
  openShiftNote: string;
};

export type CloseShiftParameters = {
  shiftId: string;
  closeShiftCashCurrencyList: ShiftCashCurrencyDto[];
};

export type UnCloseShiftParameters = {
  shiftId: string;
};

export type ShiftInfo = {
  id: string;
  shiftState: ShiftState;
  code: string;
  employee: string;
  pos: string;
  store: string;
  openDateUTC: Date | string;
  closeDateUTC?: Date | string;
};

export type OpenShiftResult = {
  status: OpenShiftStatus;
  shiftInfo: ShiftInfo | null;
};

export const EmptyShiftInfoDto: ShiftInfo = {
  id: uuidNil,
  shiftState: ShiftState.Closed,
  code: "",
  employee: "",
  pos: "",
  store: "",
  openDateUTC: "",
  closeDateUTC: "",
};

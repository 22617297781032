export enum LoginErrorStatus {
  InvalidOrganizationIdentifier,
  EmailIsNotVerified,
  InvalidLogin,
  UserNotActive,
  SuperVisorLoginPos,
}

export interface ILoginError {
  status: LoginErrorStatus;
  error: { name: string; value: string };
}

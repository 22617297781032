import { TicketItem } from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../handler";
import { executeHandler, qtyHandler } from "./GeneralTicketItemChain";

export class ValueHandler extends PosHandler<TicketItem> {
  protected processHandle(
    props: HandlerProps<TicketItem>
  ): Promise<IHandlerResult<TicketItem>> {
    const { item, value } = props;

    if (value) {
      return executeHandler(qtyHandler, {
        item,
        value: Number(value).dividedBy(item.price),
      });
    } else item.value = Number(item.qty).multipliedBy(item.price);

    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}

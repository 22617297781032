import { CardMedia, Stack, useTheme } from "@mui/material";

export default function FormLogo() {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";

  return (
    <Stack maxWidth="sm" direction="row">
      <CardMedia
        component="img"
        image={`/images/${
          isDarkTheme ? "alameenPOSProLogoDark" : "alameenPOSProLogo"
        }.svg`}
        sx={{ width: "100%" }}
        alt="alameen POS Pro Logo"
      />
    </Stack>
  );
}

import { TicketItem } from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../handler";

export class NetHandler extends PosHandler<TicketItem> {
  protected processHandle(
    props: HandlerProps<TicketItem>
  ): Promise<IHandlerResult<TicketItem>> {
    const { item } = props;
    item.net = item.total.minus(item.discountValue);

    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}

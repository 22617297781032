import Ticket, { TicketItem } from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../handler";

export class ItemsHandler extends PosHandler<Ticket> {
  protected processHandle(
    props: HandlerProps<Ticket>
  ): Promise<IHandlerResult<Ticket>> {
    const { item, value } = props;

    if (value) {
      item.ticketItems = value as TicketItem[];
    }
    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}

import { TicketItem } from "amnPos/ticket/Ticket";
import {
  PosValidator,
  ValidateResault,
  IValidatorProps,
  ValidateStatus,
} from "../validator";
import { store } from "app/store";

export class GiftValidator extends PosValidator<TicketItem> {
  public validate(props: IValidatorProps<TicketItem>): ValidateResault {
    const { item, value } = props;
    const user = store.getState().user;

    const giftQty = Number(value) || Number(item.giftQty);
    const totalGiftValue = giftQty.multipliedBy(item.price);
    const gifPercentage = totalGiftValue
      .dividedBy(item.value)
      .multipliedBy(100);

    if (Number(giftQty).isLessThan(0))
      return {
        status: ValidateStatus.Error,
        errorMsgTitle: "giftNotAllowed",
        errorMsg: "valueIsLessThanZero",
      };

    if (giftQty.isGreaterThan(item.qty))
      return {
        status: ValidateStatus.Error,
        errorMsgTitle: "giftNotAllowed",
        errorMsg: "giftIsGreaterThanItemValue",
      };

    if (gifPercentage > user.itemDisCountPercentage) {
      return {
        status: ValidateStatus.Error,
        errorMsgTitle: "discountNotAllowed",
        errorMsg: "discountPercentageGreaterThanAllowed",
      };
    }

    return { status: ValidateStatus.Success };
  }
}

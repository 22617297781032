import { TotalHandler } from "./TotalHandler";
import { TypeHandler } from "./TypeHandler";
import { NumberHandler } from "./NumberHandler";
import { CodeHandler } from "./CodeHandler";
import { ItemsHandler } from "./ItemsHandler";
import { StatusHandler } from "./StatusHandler";
import { ClientHandler } from "./ClientHandler";
import { DiscountHandler } from "./DiscountHandler";

export const typeHandler = new TypeHandler();
export const numberHandler = new NumberHandler();
export const codeHandler = new CodeHandler();
export const itemsHandler = new ItemsHandler();
export const totalHandler = new TotalHandler();
export const statusHandler = new StatusHandler();
export const clientHandler = new ClientHandler();
export const discountHandler = new DiscountHandler();

typeHandler
  .setNext(numberHandler)
  .setNext(codeHandler)
  .setNext(itemsHandler)
  .setNext(discountHandler)
  .setNext(totalHandler);

import { InputAdornment, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import { forwardRef } from "react";

const StandardTextField = forwardRef((props: any, ref) => {
  const { startIcon, endIcon, ...other } = props;
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";

  return (
    <TextField
      ref={ref}
      variant="standard"
      {...other}
      InputProps={{
        startAdornment: startIcon ? (
          <InputAdornment
            position="start"
            sx={{ color: isDarkTheme ? "" : "#08233F" }}
          >
            {startIcon}
          </InputAdornment>
        ) : undefined,
        endAdornment: endIcon ? (
          <InputAdornment position="end">{endIcon}</InputAdornment>
        ) : undefined,
      }}
    />
  );
});

export default StandardTextField;

import { TicketItem } from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../handler";

export class PriceHandler extends PosHandler<TicketItem> {
  protected processHandle(
    props: HandlerProps<TicketItem>
  ): Promise<IHandlerResult<TicketItem>> {
    const { item, value } = props;
    if (value) {
      item.price = Number(value);
    }

    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}

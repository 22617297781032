export default function fixViewPortHeight() {
  window.addEventListener("load", () => {
    const viewport = document.querySelector(
      "meta[name=viewport]"
    ) as HTMLMetaElement;

    viewport.setAttribute(
      "content",
      viewport.content + ", height=" + window.innerHeight
    );
  });
}

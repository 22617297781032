import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import IAsyncModel from "models/IAsyncModel";
import amnPosApi from "api/amnPosApi";
import IEmployeeData, {
  EmptyEmployee,
} from "adminPanel/employee/models/IEmployee";

export interface EmployeeSettingSlice extends IAsyncModel, IEmployeeData {}

const initialState: EmployeeSettingSlice = EmptyEmployee;

export const getEmployeeSettingAsync = createAsyncThunk(
  "pos/EmployeeSetting",
  async () => {
    const resp = await amnPosApi.GetEmployeeSetting();

    return {
      id: resp.data.id,
      name: resp.data.name,
      image: "",
      isSupervisor: resp.data.isSupervisor,
      isActive: resp.data.isActive,
      email: resp.data.email,
      password: "",
      phone: resp.data.phone,
      updatedOn: resp.data.updatedOn,

      clientOptions: resp.data.clientOptions,
      transactionsOptions: resp.data.transactionsOptions,
      transactionPermissionsOptions: resp.data.transactionPermissionsOptions,
      shiftOptions: resp.data.shiftOptions,
      orderOptions: resp.data.orderOptions,
    } as IEmployeeData;
  }
);

export const employeeSettingSlice = createSlice({
  name: "employeeSetting",
  initialState,
  reducers: {
    initializeEmployeeSeetings: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeSettingAsync.fulfilled, (_, action) => {
        return action.payload;
      })
      .addCase(getEmployeeSettingAsync.rejected, () => {
        return initialState;
      });
  },
});

export const { initializeEmployeeSeetings } = employeeSettingSlice.actions;

export const employeeSettings = (state: RootState) => state.employeeSettings;

export default employeeSettingSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export interface RouterState<T> {
  pathname: string;
  data: T;
  isDirty: boolean;
}

export interface RouterStates {
  [key: string]: RouterState<any>;
}

var initialState: RouterStates = {};

function emptyRouterState(key: string) {
  initialState = {
    [key]: { pathname: "", data: null, isDirty: false } as RouterState<any>,
  } as RouterStates;
  return initialState;
}

export const localRouterSlice = createSlice({
  name: "localRouter",
  initialState,
  reducers: {
    resetLocalRouter: () => initialState,
    initializeRouter: (state, action) => {
      const key = action.payload;
      if (!state[key]) state = { ...state, ...emptyRouterState(key) };
      return state;
    },
    setPathname: (state, action) => {
      const { key, pathname } = action.payload;

      state[key].pathname = pathname;
    },
    setData: (state, action) => {
      const { key, data } = action.payload;
      state[key].data = data;
    },
    setIsDirty: (state, action) => {
      const { key, isDirty } = action.payload;

      state[key].isDirty = isDirty;
    },
    initailizeIsDirty: (state) => {
      for (const key in state) {
        if (state[key]) {
          state[key].isDirty = false;
        }
      }
    },
  },
});

export const {
  resetLocalRouter,
  initializeRouter,
  setPathname,
  setData,
  setIsDirty,
  initailizeIsDirty,
} = localRouterSlice.actions;

export const localRouter = (state: RootState) => state.localRouter;

export default localRouterSlice.reducer;

import {
  EmptyStorePrinter,
  IStoreAutoComplete,
  IStorePrinter,
} from "adminPanel/stores/IStore";
import { TransactionTypes } from "models/common-props/TransactionTypes";
import { NIL } from "uuid";

export interface IPosReturnOptions {
  makeReturns: boolean;
  makeExchange: boolean;
  makeReturnsWithinDays: boolean;
  returnWithinDays: number;
  returnFromSalesWithOffer: boolean;
  returnCash: boolean;
  returnForeignCurrencies: boolean;
  returnAllowExchangeWithLessValueFromSales: boolean;
}

export interface IPosOrdersOptions {
  dealWithPickupOrders: boolean;
  dealWithDeliveryOrders: boolean;
  ordersReturningReasonIsMandatory: boolean;
  ordersCancellingReasonIsMandatory: boolean;
}

export enum RoundDirection {
  Up,
  Down,
}

export interface IPosRoundOptions {
  useRound: boolean;
  roundDirection: RoundDirection;
  roundToNearest: number;
}

export interface IPosSettleOptions {
  settleWithCash: boolean;
  settleInDebt: boolean;
  settleUseCashButton: boolean;
  settleUseSettlementButton: boolean;
  settleCashDiscount: boolean;
}

export interface IPrintDesign {
  id?: string;
  name: string;
  language: string;
}

export interface IPosPrintDesign {
  id: string;
  posId: string;
  printDesign: IPrintDesign;
  storePrinter: IStorePrinter | null;
  transactionType: TransactionTypes;
  askBeforePrint: boolean;
}
export interface IStorePrinterCustomizationDto {
  id: string;
  storePrinterId: string;
  printer?: string;
  paperSize?: string;
}
export interface ICurrency {
  id: string;
  code: string;
  name: string;
  name_Ar: string;
  flagCode: string;
  iso: string;
  isDefault: boolean;
  exchangeFactor: number;
}

export interface IPosUser {
  id: string;
  user: IUser;
  name: string;
  isActive: boolean;
  isSupervisor: boolean;
}

export interface IUser {
  id: string;
  name: string;
}

export interface IStoreDto {
  id: string;
  name: string;
  storePrinterCustomizations?: IStorePrinterCustomizationDto[];
}

export const EmptyStoreAutoComplete: IStoreAutoComplete = {
  id: NIL,
  name: "",
};

export const EmoptyPrintDesign = {
  id: "",
  name: "",
  language: "",
};

export const EmptyPosPrintDesignSale: IPosPrintDesign = {
  id: "",
  posId: "",
  printDesign: EmoptyPrintDesign,
  storePrinter: EmptyStorePrinter,
  transactionType: TransactionTypes.sales,
  askBeforePrint: true,
};

export const EmptyPosPrintDesignSalesReturns: IPosPrintDesign = {
  id: "",
  posId: "",
  printDesign: EmoptyPrintDesign,
  storePrinter: EmptyStorePrinter,
  transactionType: TransactionTypes.salesReturns,
  askBeforePrint: true,
};

export const EmptyPosPrintDesignDeliveryOrder: IPosPrintDesign = {
  id: "",
  posId: "",
  printDesign: EmoptyPrintDesign,
  storePrinter: EmptyStorePrinter,
  transactionType: TransactionTypes.deliveryOrder,
  askBeforePrint: true,
};

export const EmptyPosPrintDesignPickUpOrder: IPosPrintDesign = {
  id: "",
  posId: "",
  printDesign: EmoptyPrintDesign,
  storePrinter: EmptyStorePrinter,
  transactionType: TransactionTypes.pickUpOrder,
  askBeforePrint: true,
};

export default interface IPosProperties {
  id: string;
  name: string;
  code: string;
  useReturnCoupons: boolean;

  updatedOn: string;
  store: IStoreDto;
  users: IPosUser[];
  currencies: ICurrency[];
  returnOptions: IPosReturnOptions;
  ordersOptions: IPosOrdersOptions;
  roundOptions: IPosRoundOptions;
  settleOptions: IPosSettleOptions;
  posPrintDesigns: IPosPrintDesign[];
  printingServiceUrl: string;
}

export const EmptyPosProperties: IPosProperties = {
  id: NIL,
  name: "",
  code: "",
  useReturnCoupons: false,

  updatedOn: "",
  store: EmptyStoreAutoComplete,
  users: [],
  currencies: [],
  returnOptions: {
    makeReturns: false,
    makeExchange: false,
    makeReturnsWithinDays: false,
    returnWithinDays: 0,
    returnFromSalesWithOffer: false,
    returnCash: false,
    returnForeignCurrencies: false,
    returnAllowExchangeWithLessValueFromSales: false,
  },
  ordersOptions: {
    dealWithPickupOrders: false,
    dealWithDeliveryOrders: false,
    ordersReturningReasonIsMandatory: false,
    ordersCancellingReasonIsMandatory: false,
  },
  roundOptions: {
    useRound: false,
    roundDirection: RoundDirection.Up,
    roundToNearest: 0,
  },
  settleOptions: {
    settleWithCash: true,
    settleInDebt: false,
    settleUseCashButton: false,
    settleUseSettlementButton: false,
    settleCashDiscount: false,
  },
  posPrintDesigns: [
    { ...EmptyPosPrintDesignSale },
    { ...EmptyPosPrintDesignSalesReturns },
    { ...EmptyPosPrintDesignDeliveryOrder },
    { ...EmptyPosPrintDesignPickUpOrder },
  ],
  printingServiceUrl: "",
};

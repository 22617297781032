import { Button, Stack, useTheme } from "@mui/material";
import { AmeenColors, AmeenColorsDark } from "constants/ameenColors";
import useHandleBarcodeActions from "hooks/useHandleBarcodeActions";
import { ReactNode } from "react";

interface IMainButtonProps {
  children: ReactNode;
  icon?: ReactNode;
  onClick?: any;
  isDisabled?: boolean;
  backgroundColor?: string;
  darkBackgroundColor?: string;
  fontColor?: string;
  width?: string;
  padding?: string;
}

export default function MainButton({
  children,
  icon,
  onClick,
  isDisabled,
  backgroundColor,
  darkBackgroundColor,
  fontColor,
  width,
  padding,
}: IMainButtonProps) {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";
  const { preventEnterKeyOnClick } = useHandleBarcodeActions();

  return (
    <Button
      onKeyDown={preventEnterKeyOnClick}
      disabled={isDisabled ? isDisabled : false}
      sx={{
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: isDarkTheme
          ? darkBackgroundColor
            ? darkBackgroundColor
            : AmeenColorsDark.Background
          : backgroundColor
          ? backgroundColor
          : AmeenColors.SkyBlue,
        color: fontColor ? fontColor : AmeenColors.Primary,
        padding: padding ? padding : "8px 22px",
        direction: "ltr",
        width: width,
        fontSize: "15px",
        ":hover": {
          bgcolor: AmeenColors.Primary,
          color: "white",
          boxShadow: "none",
        },
        ":disabled": {
          bgcolor: "#F4F8FD",
          color: AmeenColors.Disabled,
        },
      }}
      onClick={() => {
        if (onClick) onClick();
      }}
      size="large"
      variant="contained"
      startIcon={icon && <Stack mr={1}>{icon}</Stack>}
    >
      {children}
    </Button>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { GridSortModel } from "@mui/x-data-grid";
import { ColumnFilterOperation } from "models/filter/IColumnFilter";
interface GridFilter {
  filed: string;
  value: any;
  column: string;
  operator: ColumnFilterOperation;
  value1: any;
}
export interface DataGridState {
  page: number;
  pageSize: number;
  appliedFilters: GridFilter[];
  totalRows: number;
  searchText: string;
  sortModel: GridSortModel;
  selectionModel: any[];
}

export interface DataGridStates {
  [key: string]: DataGridState;
}

var initialState: DataGridStates = {};

function emptyDataGridState(key: string) {
  initialState = {
    [key]: {
      page: 0,
      pageSize: 5,
      appliedFilters: [],
      totalRows: 0,
      searchText: "",
      sortModel: [],
      selectionModel: [],
    } as DataGridState,
  } as DataGridStates;
  return initialState;
}

export const dataGridSlice = createSlice({
  name: "dataGrid",
  initialState,
  reducers: {
    initialize: (state, action) => {
      const key = action.payload;
      if (!state[key]) state = { ...state, ...emptyDataGridState(key) };
      return state;
    },
    changeTotalRows: (state, action) => {
      const { key, totalRows } = action.payload;
      state[key].totalRows = totalRows;
    },
    changePage: (state, action) => {
      const { key, pageSize, page } = action.payload;
      state[key].page = page;
      state[key].pageSize = pageSize;
    },
    onSearchChange: (state, action) => {
      const { key, searchText } = action.payload;
      state[key].searchText = searchText;
    },
    setAppliedFilters: (state, action) => {
      const { key, newFilter } = action.payload;

      state[key].appliedFilters.map((f: any, index: number) => {
        if (f.column === newFilter.column)
          state[key].appliedFilters.splice(index, 1);
        return f;
      });

      if (newFilter.value1 !== "") state[key].appliedFilters.push(newFilter);
      return state;
    },
    changeSortModel: (state, action) => {
      const { key, sortModel } = action.payload;
      state[key].sortModel = sortModel;
    },
    changeSelectionModel: (state, action) => {
      const { key, selectionModel } = action.payload;
      if (state[key]) {
        state[key].selectionModel = selectionModel;
      }
    },
  },
});

export const {
  changePage,
  onSearchChange,
  setAppliedFilters,
  changeSortModel,
  initialize,
  changeSelectionModel,
  changeTotalRows,
} = dataGridSlice.actions;

export const dataGrid = (state: RootState) => state.dataGrid;

export default dataGridSlice.reducer;

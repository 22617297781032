import {
  Alert,
  AlertColor,
  Button,
  Snackbar,
  SnackbarCloseReason,
} from "@mui/material";
import IChildrenProps from "models/common-props/IChildrenProps";
import {
  MouseEventHandler,
  createContext,
  useCallback,
  useMemo,
  useState,
} from "react";

export interface ISnackContext {
  setSnack: (
    message: string,
    severity?: string,
    duration?: number,
    actionName?: string,
    action?: Function
  ) => void;
}

const SnackbarContext = createContext<ISnackContext>({
  setSnack: (
    message: string,
    severity?: string,
    duration?: number,
    actionName?: string,
    action?: Function
  ) => {},
});

export function SnackbarContextProvider({ children }: IChildrenProps) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [duration, setDuration] = useState(4000);
  const [actionName, setActionName] = useState("");
  const [action, setAction] = useState<Function | null>(null);

  function handleCloseSnackBar(
    event: React.SyntheticEvent<any> | Event,
    reason: SnackbarCloseReason
  ) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage("");
    setSeverity("info");
    setDuration(4000);
    setActionName("");
    setAction(null);
  }

  const setSnack = useCallback(
    (
      message: string,
      severity?: string,
      duration?: number,
      actionName?: string,
      action?: Function
    ) => {
      setOpen(true);
      setMessage(message);

      if (severity) setSeverity(severity);
      if (duration) setDuration(duration);
      if (actionName) setActionName(actionName);
      if (action) setAction(() => action);
    },
    []
  );

  const context = useMemo(() => ({ setSnack }), [setSnack]);

  return (
    <SnackbarContext.Provider value={context}>
      {children}
      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        open={open}
        autoHideDuration={duration}
        onClose={handleCloseSnackBar}
      >
        <Alert
          //onClose={handleCloseSnackBar}
          severity={severity as AlertColor}
          variant="filled"
          sx={{ width: "100%" }}
          action={
            actionName && (
              <Button
                color="inherit"
                size="small"
                variant="outlined"
                onClick={action as MouseEventHandler}
              >
                {actionName}
              </Button>
            )
          }
        >
          {message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

export default SnackbarContext;

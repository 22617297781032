import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import {
  EmptyShiftInfoDto,
  ShiftInfo,
  ShiftState,
} from "amnPos/shiftsAndExternals/shift/ShiftDataModel";
import shiftAPI from "amnPos/shiftsAndExternals/shift/shiftAPI";
import { RootState } from "app/store";
import IAsyncModel from "models/IAsyncModel";

export interface ShiftInfoSlice extends IAsyncModel, ShiftInfo {}
const initialState: ShiftInfoSlice = EmptyShiftInfoDto;
export const getShiftInfoAsync = createAsyncThunk(
  "pos/ShiftInfo",
  async (parms: { posId: string }) => {
    const resp = await shiftAPI.GetShiftInfo(parms.posId);

    if (resp.data) {
      const openDate = moment(resp.data.openDateUTC).format(
        "DD MMM YYYY, h:mm a"
      );

      const closeDate =
        resp.data.shiftState === ShiftState.Closed
          ? moment(resp.data.closeDateUTC).format("DD MMM YYYY, h:mm a")
          : "";

      return {
        ...resp.data,
        openDateUTC: openDate,
        closeDateUTC: closeDate,
      } as ShiftInfo;
    }

    return initialState;
  }
);

export const shiftInfoSlice = createSlice({
  name: "shiftInfo",
  initialState,
  reducers: {
    initializeShiftInfo: () => {
      return initialState;
    },
    resetShiftInfo: (state, action: PayloadAction<ShiftInfo>) => {
      state = action.payload;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getShiftInfoAsync.fulfilled, (_, action) => {
        return action.payload;
      })
      .addCase(getShiftInfoAsync.rejected, () => {
        return initialState;
      });
  },
});
export const { initializeShiftInfo, resetShiftInfo } = shiftInfoSlice.actions;

export const shiftInfo = (state: RootState) => state.shiftInfo;

export default shiftInfoSlice.reducer;

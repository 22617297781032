import { TicketItem } from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../handler";
import { GiftValidator } from "amnPos/validators/ticketItemValidators/GiftValidator";

export class GiftHandler extends PosHandler<TicketItem> {
  constructor() {
    super();
    this.validator = new GiftValidator();
  }

  protected processHandle(
    props: HandlerProps<TicketItem>
  ): Promise<IHandlerResult<TicketItem>> {
    const { item, value } = props;

    const giftQty = Number(value);

    if (giftQty.isGreaterThanOrEqualTo(0)) {
      item.giftQty = giftQty;
    }

    item.giftsValue = item.giftQty.multipliedBy(item.price);
    item.giftsPercent = item.giftsValue.dividedBy(item.value).multipliedBy(100);

    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}

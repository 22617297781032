import axios from "axios";
import { MutableRefObject, useRef } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import useCompare from "hooks/useCompare";
import { useAppDispatch } from "app/hooks";
import { signoutAsync } from "adminPanel/user/userSlice";
import useSnackbar, { ISnackbar } from "hooks/useSnackbar";
import IChildrenProps from "models/common-props/IChildrenProps";
import i18n from "localization/i18n";
import getOrganization from "helpers/getOrganization";

function reloadInterceptors(
  requestInterceptorIdRef: MutableRefObject<number | null>,
  responseInterceptorIdRef: MutableRefObject<number | null>,
  navigate: NavigateFunction,
  logOut: () => void,
  snackContext: ISnackbar,
  language: string,
  organizationName: string
) {
  axios.interceptors.request.eject(requestInterceptorIdRef.current ?? -1);
  axios.interceptors.response.eject(responseInterceptorIdRef.current ?? -1);

  requestInterceptorIdRef.current = axios.interceptors.request.use(
    (config) => {
      const headerAcceptLanguage = "Accept-Language";

      if (language) {
        config.headers[headerAcceptLanguage] = language;
      } else {
        delete config.headers[headerAcceptLanguage];
      }

      const headerOrganizationName = "Organization-Name";

      if (organizationName) {
        config.headers[headerOrganizationName] = organizationName;
      } else {
        delete config.headers[headerOrganizationName];
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  responseInterceptorIdRef.current = axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const resp = error.response;
        const responseType = error.request.responseType;
        if (resp.status === 401) {
          //logOut();
        } else if (resp.status === 500) {
          if (responseType !== "blob" && process.env.NODE_ENV === "development")
            navigate("/error", {
              replace: true,
              state: {
                error: resp.data,
              },
            });
          else snackContext.setSnack("Internal Service Error.", "error");
        }
      }
      return Promise.reject(error);
    }
  );
}

export default function HttpInterceptor({ children }: IChildrenProps) {
  const dispatch = useAppDispatch();
  const requestInterceptorIdRef = useRef<number | null>(null);
  const responseInterceptorIdRef = useRef<number | null>(null);
  const snackContext = useSnackbar();

  const navigate = useNavigate();
  const languageChanged = useCompare(i18n.language);

  if (
    !requestInterceptorIdRef.current ||
    !responseInterceptorIdRef.current ||
    languageChanged
  )
    reloadInterceptors(
      requestInterceptorIdRef,
      responseInterceptorIdRef,
      navigate,
      () => dispatch(signoutAsync()),
      snackContext,
      i18n.language,
      getOrganization()
    );

  return children;
}

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import IAsyncModel from "models/IAsyncModel";
import { NIL, NIL as uuidNil } from "uuid";
import userApi from "adminPanel/user/userApi";
import IOrganizationLoginDto from "adminPanel/user/models/IOrganizationLoginDto";
import IUserLoginDto from "adminPanel/user/models/IUserLoginDto";
import IAsyncThunkInput from "models/IAsyncThunkInput";
import IPosUserLoginDto from "adminPanel/pos/models/IPosUserLoginDto";
import getOrganization from "helpers/getOrganization";
import i18n from "localization/i18n";
import { IUserProfileInfo } from "./models/IUser";
import getUserPreferedLanguage, {
  getUserNumberingSystem,
  getUserPreferedColorTheme,
} from "./userModelHealper";
import {
  initializeShiftInfo,
  resetShiftInfo,
} from "amnPos/slices/ShiftInfoSlice";

export interface UserState extends IAsyncModel {
  id: string;
  userName: string;
  image?: string;
  code: string;
  isAdmin: boolean;
  isCasher: boolean;
  organizationId: string;
  organizationEmail: string;
  posUserId?: string;
  liftTicketsCount: number;
  preferedLanguage: string;
  preferedColorTheme: string;
  priceDecimalPlaces: number;
  qtyDecimalPlaces: number;
  numberingSystem: string;
  exchangeRate: number;
  makeDiscountOnItem: boolean;
  itemDisCountPercentage: number;
}

const initialState: UserState = {
  organizationId: "",
  organizationEmail: "",
  id: uuidNil,
  userName: "",
  image: "",
  code: "",
  posUserId: uuidNil,
  isAdmin: false,
  isCasher: false,
  liftTicketsCount: 0,
  preferedLanguage: "",
  preferedColorTheme: "",
  priceDecimalPlaces: 0,
  qtyDecimalPlaces: 0,
  exchangeRate: 0,
  numberingSystem: "",
  makeDiscountOnItem: false,
  itemDisCountPercentage: 0,
};

export const getConnectedAsync = createAsyncThunk(
  "user/getConnected",
  async (_, { dispatch }) => {
    const posId = localStorage.getItem("posId");
    const resp = await userApi.getConnected(posId || NIL);

    if (resp.data.shift) dispatch(resetShiftInfo(resp.data.shift));

    return {
      id: resp.data.id,
      userName: resp.data.userName,
      image: resp.data.image,
      code: resp.data.code,
      isAdmin: resp.data.isAdmin,
      isCasher: resp.data.isCasher,
      organizationId: resp.data.organizationId,
      organizationEmail: resp.data.organizationEmail,
      posUserId: resp.data.posUserId,
      preferedColorTheme: resp.data.preferedColorTheme,
      preferedLanguage: resp.data.preferedLanguage,
      priceDecimalPlaces: resp.data.priceDecimalPlaces,
      qtyDecimalPlaces: resp.data.qtyDecimalPlaces,
      numberingSystem: resp.data.numberingSystem,
      exchangeRate: resp.data.exchangeRate,
      makeDiscountOnItem: resp.data.makeDiscountOnItem,
      itemDisCountPercentage: resp.data.itemDisCountPercentage,
    } as UserState;
  }
);

export const loginOrganizationAsync = createAsyncThunk(
  "user/loginOrganizationAsync",
  async (dto: IAsyncThunkInput<IOrganizationLoginDto>) => {
    try {
      const resp = await userApi.loginOrganization(dto.data);
      if (dto.onSuccess) dto.onSuccess();
      return {
        organizationId: resp.data.organziatonId,
        organizationEmail: resp.data.email,
      } as UserState;
    } catch (e) {
      if (dto.onError) dto.onError(e);
      throw e;
    }
  }
);

export const loginAsync = createAsyncThunk(
  "user/loginAsync",
  async (dto: IAsyncThunkInput<IUserLoginDto>, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const user = state.user;
      const resp = await userApi.login(dto.data);
      if (dto.onSuccess)
        dto.onSuccess(
          resp.data.organizationId,
          resp.data.user.isCasher,
          resp.data.posId,
          resp.data.posUserId
        );

      if (resp.data.shift) dispatch(resetShiftInfo(resp.data.shift));
      if (resp.data.posId != null)
        localStorage.setItem("posId", resp.data.posId);
      else localStorage.removeItem("posId");

      const currentLanguage = localStorage.getItem("localLanguage");
      const currentTheme = localStorage.getItem("localMode");
      if (currentLanguage !== resp.data.preferedLanguage) {
        localStorage.setItem("localLanguage", resp.data.preferedLanguage);
        i18n.changeLanguage(resp.data.preferedLanguage);
        window.location.reload();
      }

      if (currentTheme !== resp.data.preferedColorTheme) {
        let localMode = resp.data.preferedColorTheme;
        if (resp.data.preferedColorTheme === "systemDefault") {
          const result = window.matchMedia("(prefers-color-scheme: dark)");
          if (result.matches) localMode = "dark";
          else localMode = "light";
        }
        localStorage.setItem("localMode", localMode);
        window.location.reload();
      }

      return {
        ...user,
        id: resp.data.user.id,
        code: resp.data.user.code,
        userName: resp.data.user.userName,
        image: resp.data.user.image,
        isAdmin: resp.data.user.isAdmin,
        isCasher: resp.data.user.isCasher,
        organizationId: resp.data.organizationId,
        posUserId: resp.data.posUserId,
        preferedLanguage: resp.data.preferedLanguage,
        preferedColorTheme: resp.data.preferedColorTheme,
        priceDecimalPlaces: resp.data.priceDecimalPlaces,
        qtyDecimalPlaces: resp.data.qtyDecimalPlaces,
        exchangeRate: resp.data.exchangeRate,
        numberingSystem: resp.data.numberingSystem,
        makeDiscountOnItem: resp.data.makeDiscountOnItem,
        itemDisCountPercentage: resp.data.itemDisCountPercentage,
      } as UserState;
    } catch (e) {
      if (dto.onError) dto.onError(e);
      throw e;
    }
  }
);

export const loginPosAsync = createAsyncThunk(
  "user/loginPosAsync",
  async (dto: IAsyncThunkInput<IPosUserLoginDto>, { getState }) => {
    try {
      const state = getState() as RootState;
      const user = state.user;
      const resp = await userApi.loginPos(dto.data);
      if (dto.onSuccess) dto.onSuccess();
      return {
        ...user,
        posUserId: resp.data.posUser.id,
        posUserCode: resp.data.posUser.code,
      } as UserState;
    } catch (e) {
      if (dto.onError) dto.onError(e);
      throw e;
    }
  }
);

export const signoutAsync = createAsyncThunk(
  "user/signout",
  async (_, { dispatch }) => {
    userApi.signOut().then(() => {
      localStorage.removeItem("posId");
      dispatch(initializeShiftInfo());
    });
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    signOutUser: (state) => {
      state = initialState;
    },
    loginUser: (state, action: PayloadAction<UserState>) => {
      state = action.payload;
    },
    setLiftTicketsCount: (state, action: PayloadAction<number>) => {
      state.liftTicketsCount = action.payload;
    },
    UpdateUserData: (state, action: PayloadAction<IUserProfileInfo>) => {
      const profileInfo = action.payload;
      state.preferedLanguage = getUserPreferedLanguage(
        profileInfo.preferedLanguage
      );
      state.preferedColorTheme = getUserPreferedColorTheme(
        profileInfo.preferedColorTheme
      );
      state.numberingSystem = getUserNumberingSystem(
        profileInfo.numberingSystem
      );
      state.priceDecimalPlaces = profileInfo.priceDecimalPlaces;
      state.qtyDecimalPlaces = profileInfo.qtyDecimalPlaces;
      state.exchangeRate = profileInfo.exchangeRate;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConnectedAsync.fulfilled, (_, action) => {
        return action.payload;
      })
      .addCase(getConnectedAsync.rejected, () => {
        return initialState;
      })
      .addCase(loginOrganizationAsync.fulfilled, (_, action) => {
        return action.payload;
      })
      .addCase(loginAsync.fulfilled, (_, action) => {
        return action.payload;
      })
      .addCase(loginPosAsync.fulfilled, (_, action) => {
        return action.payload;
      })
      .addCase(signoutAsync.fulfilled, () => {
        return {
          ...initialState,
          organizationId: getOrganization(),
        };
      });
  },
});

export const { loginUser, signOutUser, setLiftTicketsCount, UpdateUserData } =
  userSlice.actions;

export const selectUser = (state: RootState) => state.user;

export default userSlice.reducer;

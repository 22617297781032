import BigNumber from "bignumber.js";
export {};
declare global {
  interface Number {
    isEqualTo(number: number): boolean;
    isLessThan(number: number): boolean;
    isLessThanOrEqualTo(number: number): boolean;
    isGreaterThanOrEqualTo(number: number): boolean;
    isGreaterThan(number: number): boolean;
    multipliedBy(number: number): number;
    dividedBy(number: number): number;
    minus(number: number): number;
    plus(number: number): number;
  }
}

Number.prototype.isEqualTo = function (this: number, number: number): boolean {
  return new BigNumber(this).isEqualTo(number);
};

Number.prototype.isLessThan = function (this: number, number: number): boolean {
  return new BigNumber(this).isLessThan(number);
};

Number.prototype.isLessThanOrEqualTo = function (
  this: number,
  number: number
): boolean {
  return new BigNumber(this).isLessThanOrEqualTo(number);
};

Number.prototype.isGreaterThanOrEqualTo = function (
  this: number,
  number: number
): boolean {
  return new BigNumber(this).isGreaterThanOrEqualTo(new BigNumber(number));
};

Number.prototype.isGreaterThan = function (
  this: number,
  number: number
): boolean {
  return new BigNumber(this).isGreaterThan(new BigNumber(number));
};

Number.prototype.multipliedBy = function (
  this: number,
  number: number
): number {
  return new BigNumber(this).multipliedBy(new BigNumber(number)).toNumber();
};

Number.prototype.dividedBy = function (this: number, number: number): number {
  return new BigNumber(this).dividedBy(new BigNumber(number)).toNumber();
};

Number.prototype.minus = function (this: number, number: number): number {
  return new BigNumber(this).minus(new BigNumber(number)).toNumber();
};

Number.prototype.plus = function (this: number, number: number) {
  return new BigNumber(this).plus(new BigNumber(number)).toNumber();
};

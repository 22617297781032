export interface IClient {
  canAddNewClient: boolean;
  canModifyExistingClient: boolean;
  canChangeDefaultCountryAndProvince: boolean;
  canAddNewAddress: boolean;
  canModifyAddress: boolean;
}
export interface ITransactions {
  sales: boolean;
  returns: boolean;
  exchange: boolean;
}

export interface ITransactionPermissions {
  changePrice: boolean;
  sellInDebt: boolean;
  deleteAnItem: boolean;
  reduceQuantity: boolean;
  viewSettledTransactions: boolean;
  reprintSettledTransaction: boolean;
  openDrawerManualy: boolean;
  makeDiscountOnItem: boolean;
  itemDisCountPercentage: number;
  makeDiscountOnTransaction: boolean;
  transactionDisCountPercentage: number;
  canUseFc: boolean;
  canPayForReturnsInFc: boolean;
  canModifyExChangeRates: boolean;
}

export interface IShift {
  closeShift: boolean;
  viewCalculatedCash: boolean;
  postCashToNextShift: boolean;
  unCloseShift: boolean;
  centralBoxReceiptNumberMandatory: boolean;
}
export interface IOrder {
  addPickUpOrder: boolean;
  addDeliveryOrder: boolean;
  editDeliveryFees: boolean;
  receivePrepayment: boolean;
  cancelOrder: boolean;
  editSavedOrder: boolean;
  useOrderManagementTab: boolean;
  useDeliveryOrdersManagement: boolean;
  settlePickupOrder: boolean;
  settleDeliveryTrip: boolean;
}

export interface IItemsAndCategoriesOptions {
  canViewItemsList: boolean;
  canAddNewItem: boolean;
  canEditExistingItem: boolean;
  canViewUnitList: boolean;
  canAddNewUnit: boolean;
  canEditExistingUnit: boolean;
  canViewCategoryList: boolean;
  canAddNewCategory: boolean;
  canEditExistingCategory: boolean;
}

export interface IReportsOptions {
  calculatedCashDetails: boolean;
  shiftDigest: boolean;
  materialsAndServicesTransactionsDetail: boolean;
  externalTransactionsDetails: boolean;
  closingDetails: boolean;
}

export default interface IEmployeeData {
  id?: string;
  name: string;
  employeeCode: string;
  image: string;
  isSupervisor: boolean;
  isActive: boolean;
  email: string;
  password: string;
  phone?: string;
  updatedOn?: string;
  clientOptions: IClient;
  transactionsOptions: ITransactions;
  transactionPermissionsOptions: ITransactionPermissions;
  shiftOptions: IShift;
  orderOptions: IOrder;
  itemsAndCategoriesOptions: IItemsAndCategoriesOptions;
  reportsOptions: IReportsOptions;
}

export const EmptyEmployee: IEmployeeData = {
  id: undefined,
  name: "",
  employeeCode: "",
  image: "",
  isSupervisor: false,
  isActive: true,
  email: "",
  password: "",
  phone: "",
  updatedOn: undefined,

  clientOptions: {
    canAddNewClient: false,
    canModifyExistingClient: false,
    canChangeDefaultCountryAndProvince: false,
    canAddNewAddress: false,
    canModifyAddress: false,
  },

  transactionsOptions: {
    sales: true,
    returns: false,
    exchange: false,
  },

  transactionPermissionsOptions: {
    changePrice: false,
    sellInDebt: false,
    deleteAnItem: false,
    reduceQuantity: false,
    viewSettledTransactions: false,
    reprintSettledTransaction: false,
    openDrawerManualy: false,
    makeDiscountOnItem: false,
    itemDisCountPercentage: 0,
    makeDiscountOnTransaction: false,
    transactionDisCountPercentage: 0,
    canUseFc: false,
    canPayForReturnsInFc: false,
    canModifyExChangeRates: false,
  },
  shiftOptions: {
    closeShift: false,
    viewCalculatedCash: false,
    postCashToNextShift: false,
    unCloseShift: false,
    centralBoxReceiptNumberMandatory: false,
  },
  orderOptions: {
    addPickUpOrder: false,
    addDeliveryOrder: false,
    editDeliveryFees: false,
    receivePrepayment: false,
    cancelOrder: false,
    editSavedOrder: false,
    useOrderManagementTab: false,
    useDeliveryOrdersManagement: false,
    settlePickupOrder: false,
    settleDeliveryTrip: false,
  },
  itemsAndCategoriesOptions: {
    canViewItemsList: false,
    canAddNewItem: false,
    canEditExistingItem: false,
    canViewUnitList: false,
    canAddNewUnit: false,
    canEditExistingUnit: false,
    canViewCategoryList: false,
    canAddNewCategory: false,
    canEditExistingCategory: false,
  },
  reportsOptions: {
    calculatedCashDetails: false,
    shiftDigest: false,
    materialsAndServicesTransactionsDetail: false,
    externalTransactionsDetails: false,
    closingDetails: false,
  },
};

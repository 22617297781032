export default function getUserPreferedLanguage(lang: number) {
  if (lang === 0) return "en";
  else return "ar";
}

export function getUserPreferedColorTheme(theme: number) {
  if (theme === 0) return "light";
  else if (theme === 1) return "dark";
  else return "systemDefault";
}

export function getUserNumberingSystem(numering: number) {
  if (numering === 0) return "ar-EG";
  else return "en-US";
}

import Ticket, { IClient } from "amnPos/ticket/Ticket";
import {
  HandlerProps,
  HandlerStatus,
  IHandlerResult,
  PosHandler,
} from "../handler";

export class ClientHandler extends PosHandler<Ticket> {
  protected processHandle(
    props: HandlerProps<Ticket>
  ): Promise<IHandlerResult<Ticket>> {
    const { item, value } = props;

    if (value) {
      const client = value as IClient;
      item.clientId = client.id;
      item.client = client.name;
    }
    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}

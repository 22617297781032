import { TicketItem } from "amnPos/ticket/Ticket";
import {
  PosHandler,
  HandlerProps,
  IHandlerResult,
  HandlerStatus,
} from "../handler";

export class TotalHandler extends PosHandler<TicketItem> {
  protected processHandle(
    props: HandlerProps<TicketItem>
  ): Promise<IHandlerResult<TicketItem>> {
    const { item, value } = props;

    item.total = Number(item.value).minus(item.giftsValue);

    return Promise.resolve({
      item: item,
      status: HandlerStatus.Success,
    });
  }
}

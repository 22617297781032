import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from "@mui/material";
import { AmeenColors, AmeenColorsDark } from "constants/ameenColors";
import { ReactNode } from "react";

interface IAmnCardProps {
  children: ReactNode;
  padding?: string;
}

export function AmnCard({ children, padding }: IAmnCardProps) {
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";

  return (
    <Card
      sx={
        isDarkTheme
          ? {
              borderRadius: "var(--1, 8px)",
              border: `1px solid ${AmeenColorsDark.SoftGray}`,

              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.05) 100%), #121212",

              boxShadow: " 0px 0px 20px 0px rgba(0, 0, 0, 0.03)",
              width: "100%",
              padding: padding ? padding : "28px 28px 4px 28px",
            }
          : {
              borderRadius: "var(--1, 8px)",
              border: `1px solid ${AmeenColors.SoftGray}`,
              boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.03)",
              width: "100%",
              padding: padding ? padding : "28px 28px 4px 28px",
            }
      }
    >
      {children}
    </Card>
  );
}

interface IAmnCardHeaderProps {
  title: string;
  paddingBottom?: string;
}

export function AmnCardHeader({ title, paddingBottom }: IAmnCardHeaderProps) {
  return (
    <CardHeader
      sx={{
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: paddingBottom ? paddingBottom : "16px",
      }}
      title={
        <Typography
          sx={{
            fontSize: "18px",
          }}
        >
          {title}
        </Typography>
      }
    />
  );
}

interface IAmnCardContentProps {
  children: ReactNode;
}

export function AmnCardContent({ children }: IAmnCardContentProps) {
  return (
    <CardContent
      sx={{
        padding: "4px 2px",
        margin: "0px",
      }}
    >
      {children}
    </CardContent>
  );
}

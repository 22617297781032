import AppContextProvider from "context/AppContextProvider";
import BrowserRouter from "components/general/BrowserRouter";
import HttpInterceptor from "components/general/HttpInterceptor";
import Router from "components/general/Router";

export default function App() {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <HttpInterceptor>
          <Router />
        </HttpInterceptor>
      </AppContextProvider>
    </BrowserRouter>
  );
}

import axios from "axios";
import getApiBaseUrl from "helpers/getApiBaseUrl";
import IPosProperties from "adminPanel/pos/models/IPosProperties";
import { IPosAutoComplete } from "amnPos/pages/UserHomePage";
import IEmployeeData from "adminPanel/employee/models/IEmployee";

const baseUrl = getApiBaseUrl();

function GetPosSetting(posId: string) {
  return axios.get<IPosProperties>(baseUrl + `posSetting/${posId}`);
}

function GetPosList() {
  return axios.get<IPosAutoComplete[]>(baseUrl + `posSetting/get-pos-list`);
}

function GetEmployeeSetting() {
  return axios.get<IEmployeeData>(baseUrl + `posSetting/get-employee-settings`);
}

const amnPosApi = {
  GetPosSetting,
  GetPosList,
  GetEmployeeSetting,
};

export default amnPosApi;

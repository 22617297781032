import { createSlice } from "@reduxjs/toolkit";
import Item from "amnPos/item/Item";
import { RootState } from "app/store";

export interface BarcodeStates {
  isReady: boolean;
  barcodeText: string;
  items: Item[];
}

var initialState: BarcodeStates = {
  isReady: true,
  barcodeText: "",
  items: [],
};

export const barcodeSlice = createSlice({
  name: "barcode",
  initialState,
  reducers: {
    initializeBarcode: () => initialState,
    setBarcodeText: (state, action) => {
      state.barcodeText = action.payload;
    },
    setIsReady: (state, action) => {
      state.isReady = action.payload;
    },
    setItems: (state, action) => {
      let newItems: Item[] = action.payload.map((item: Item) => ({
        ...item,
        qty: item.qty ? item.qty : 0,
        total: item.total ? item.total : 0,
      }));
      state.items = newItems;
    },
  },
});

export const { initializeBarcode, setBarcodeText, setIsReady, setItems } =
  barcodeSlice.actions;

export const barcode = (state: RootState) => state.barcode;

export default barcodeSlice.reducer;

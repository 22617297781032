import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "app/store";
import App from "App";
import "index.css";
import fixViewPortHeight from "helpers/fixViewPortHeight";
import { getConnectedAsync } from "adminPanel/user/userSlice";
import axios from "axios";
import CenteredCircularProgress from "components/general/CenteredCircularProgress";

fixViewPortHeight();

if (import.meta.env.DEV) axios.defaults.withCredentials = true;

await store.dispatch(getConnectedAsync());

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={<CenteredCircularProgress />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { NIL as uuidNil } from "uuid";

export interface POSDataGridState {
  page: number;
  pageSize: number;
  totalRows: number;
  searchText: string;
  selectedCategoryId: string;
  isShowItemsSelected: boolean;
  isSearchTextBoxHidden: boolean;
  isLoading: boolean;
}

var initialState: POSDataGridState = {
  page: 1,
  pageSize: 16,
  totalRows: 0,
  searchText: "",
  selectedCategoryId: uuidNil,
  isShowItemsSelected: true,
  isSearchTextBoxHidden: true,
  isLoading: false,
};

export const posDataGridSlice = createSlice({
  name: "POSDataGrid",
  initialState,
  reducers: {
    initialize: (state) => {
      return state;
    },
    resetGridToDefault: (state) => {
      state.page = 1;
      state.pageSize = 16;
      state.totalRows = 0;
    },
    changeTotalRows: (state, action: PayloadAction<number>) => {
      state.totalRows = action.payload;
    },
    onChangePage: (state, action) => {
      state.page = action.payload;
    },
    onChangePageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    onSearchChange: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    onselectedCategoryIdChange: (state, action: PayloadAction<string>) => {
      state.selectedCategoryId = action.payload;
    },
    onIsShowItemsChange: (state) => {
      state.isShowItemsSelected = !state.isShowItemsSelected;
    },
    onSearchTextBoxHiddenChange: (state) => {
      state.isSearchTextBoxHidden = !state.isSearchTextBoxHidden;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      return state;
    },
  },
});

export const {
  onChangePage,
  onChangePageSize,
  onSearchChange,
  initialize,
  changeTotalRows,
  onselectedCategoryIdChange,
  onIsShowItemsChange,
  resetGridToDefault,
  onSearchTextBoxHiddenChange,
  setIsLoading,
} = posDataGridSlice.actions;

export const dataGrid = (state: RootState) => state.dataGrid;

export default posDataGridSlice.reducer;

import {
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Link,
  Alert,
  AlertTitle,
  useTheme,
} from "@mui/material";
import CenteredCard from "components/general/CenteredCard";
import PasswordTextField from "components/general/PasswordTextField";
import StandardTextField from "components/general/StandardTextField";
import FormLogo from "components/icons/FormLogo";
import { useForm } from "react-hook-form";
import { AmnCardContent } from "components/general/AmnCard";
import useTranslate from "hooks/useTranslate";
import { Link as RouterLink } from "react-router-dom";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  ILoginError,
  LoginErrorStatus,
} from "adminPanel/user/models/LoginErrorStatus";
export interface ILoginFormData {
  name: string;
  password: string;
  rememberMe: boolean;
}

export interface ILoginPageProps {
  submitHandler: (data: ILoginFormData) => void;
  resendEmail: () => void;
  backgroundImage?: string;
  error?: ILoginError;
}

export default function LoginPage({
  submitHandler,
  resendEmail,
  backgroundImage,
  error,
}: ILoginPageProps) {
  const { translate, messageTrans } = useTranslate("shared.signIn");
  const theme = useTheme();
  const isDarkTheme = theme.palette.mode === "dark";

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ILoginFormData>();

  const alert = error && (
    <Alert severity="error">
      <AlertTitle sx={{fontSize:"16px", color:"#5F2120"}}> {translate(error.error.name)}</AlertTitle>
      <span style={{fontSize:"14px", fontWeight:"300"}}>{error.error.value} </span>
    </Alert>
  );

  const cardActions = (
    <Stack alignItems="center" width={"100%"}
    sx={{
      marginTop:"31px !important",
    }}
    >
      <Button
        variant="contained"
        fullWidth
        onClick={handleSubmit(submitHandler)}
        disableElevation
        sx={{
          lineHeight: "2.42 !important",
          fontWeight: "350",
        }}
      >
        {translate("signIn")}
      </Button>
      {error && error.status === LoginErrorStatus.EmailIsNotVerified && (
        <Button
          fullWidth
          onClick={resendEmail}
          sx={{ mt: 1, fontSize: "14px",
           }}
        >
          {translate("resend")}
        </Button>
      )}
      <Typography
        mt="24px"
        fontSize={"14px"}
        color={"#7A8B98"}
        fontWeight={400}
      >
        {translate("createNewAccount")}{" "}
        <Link sx={{
          fontWeight:"550",
          textDecoration: "none",
        }} component={RouterLink} to={"/signup"}>
          {translate("signUp")}
        </Link>
      </Typography>
    </Stack>
  );

  const cardHeader = (
    <Stack alignItems={"center"} textAlign={"center"}>
      <Box width={"192px"} height={"92px"}>
        <FormLogo />
      </Box>
      <Typography mt={2} mb={"12px"} fontSize={"28px"} fontWeight={600}>
        {translate("login")}
      </Typography>
      <Stack direction={"row"} spacing={1}>
        <Typography fontSize={"16px"} color={"#7A8B98"} fontWeight={400}>
          {translate("welcome")}
        </Typography>
        <Typography fontSize={"16px"} color={"#7A8B98"} fontWeight={300}>
          {translate("loginAccountMessage")}
        </Typography>
      </Stack>
    </Stack>
  );
  const cardContent = (
    <Stack spacing={"20px"} mb={2}>
      {cardHeader}
      {error && alert}
      <StandardTextField
        variant="outlined"
        fullWidth
        inputProps={register("name", {
          required: {
            value: true,
            message: "requiredError",
          },
        })}
        label={translate("name")}
        error={Boolean(errors.name)}
        helperText={messageTrans(errors.name?.message as string)}
        startIcon={<PersonOutlinedIcon />}
      />
      <PasswordTextField
        variant="outlined"
        fullWidth
        label={translate("password")}
        inputProps={register("password", {
          required: {
            value: true,
            message: "requiredError",
          },
        })}
        startIcon={<LockOutlinedIcon />}
        error={Boolean(errors.password)}
        helperText={messageTrans(errors.password?.message as string)}
      />

      <FormControlLabel
      
        control={
          <Checkbox
            {...register("rememberMe")}
            checked={watch("rememberMe") || false}
            sx={{
              mr: 1,
              background: "none",
              color: isDarkTheme ? "" : "#08233F",
            }}
          />
        }
        sx={{
          marginTop: "10px !important",
          marginBottom: "19px !important",
          "& .muirtl-4dpi1p-MuiTypography-root": {
            fontWeight: "420",
            fontSize: "0.8571428571428571rem !important"
          },
        }}
        label={translate("rememberMe")}
      />
      {cardActions}
    </Stack>
  );

  return (
    <CenteredCard showLanguageSelector backgroundImage={backgroundImage}>
      <AmnCardContent>{cardContent}</AmnCardContent>
    </CenteredCard>
  );
}

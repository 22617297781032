import { TicketItem } from "amnPos/ticket/Ticket";
import { IHandler, HandlerProps, IHandlerResult } from "../handler";
import { PriceHandler } from "./PriceHander";
import { QtyHandler } from "./QtyHandler";
import { TotalHandler } from "./TotalHandler";
import { NetHandler } from "./NetHandler";
import { DiscountHandler } from "./DiscountHandler";
import { InitHandler } from "./InitHandler";
import { ValueHandler } from "./ValueHandler";
import { GiftHandler } from "./GiftHandler";

export const initHandler = new InitHandler();
export const qtyHandler = new QtyHandler();
export const priceHandler = new PriceHandler();
export const valueHandler = new ValueHandler();
export const totalHandler = new TotalHandler();
export const netHandler = new NetHandler();
export const discountHandler = new DiscountHandler();
export const giftHandler = new GiftHandler();

initHandler
  .setNext(qtyHandler)
  .setNext(priceHandler)
  .setNext(valueHandler)
  .setNext(giftHandler)
  .setNext(totalHandler)
  .setNext(discountHandler)
  .setNext(netHandler);

export async function executeHandler(
  handler: IHandler<TicketItem>,
  props: HandlerProps<TicketItem>
): Promise<IHandlerResult<TicketItem>> {
  return await handler.handle({
    item: structuredClone(props.item),
    value: props.value,
  });
}

import { CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";

export default function CenteredCircularProgress() {
  return (
    <Stack
      width={"100%"}
      height={"85vh"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <CircularProgress />
    </Stack>
  );
}
